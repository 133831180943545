import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GamePage from './pages/GamePage';
import LeaderboardPage from './pages/LeaderboardPage';
import LeaderboardTeamPage from './pages/LeaderboardTeamPage';
import RoundsLeaderboardPage from './pages/RoundsLeaderboardPage';
import TeamPlayersPage from './pages/TeamPlayersPage';
import Message from './components/Message';
import WithSound from './common-libs/components/WithSound';

import './common-libs/style.scss';
import 'animate.css'
import './App.css';

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={'/:quizId'} element={<GamePage/>}/>
					<Route path={'/:quizId/leaderboard'} element={<LeaderboardPage/>}/>
					<Route path={'/:quizId/rounds-leaderboard'} element={<RoundsLeaderboardPage/>}/>
					<Route path={'/:quizId/teams-leaderboard'} element={<LeaderboardTeamPage/>}/>
					<Route path={'/:quizId/team-players'} element={<TeamPlayersPage/>}/>
					<Route path={'*'} element={<Message msg={'Error 404'}/>}/>
				</Routes>
			</BrowserRouter>
			<WithSound sound={`${process.env.REACT_APP_S3_BUCKET}/sounds/silence.mp3`} loop={true}/>
		</>
	)
}

export default App;
